.card {
    width: 400px !important;
    height: auto !important;
    margin: 15px;
}

.content {
    width: 100%;
    height: 100%;
}
.more-btn {
    text-align: center;
    font-size: 1.8rem;
}

/*LeafletMap {*/
/*    position: unset;*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/
