html {
  height: 100%;
}
body {
  min-height: 100%;
}

.App {
  text-align: center;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png);
}

.home-btn {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px 0;
  border-radius: 15px;
  width: 80px;
  background-color: white;
  z-index: 2;
  text-align: center;
  text-decoration: none;
  color: black;
}

/* flex setup */
.flx,
.row,
.col,
.row-reverse,
.frow,
.fcol,
.spc,
.arnd,
.even,
.ctr,
.wrap,
.ctr-main-axis,
.ctr-cross-axis,
.start-main-axis,
.start-sec-axis,
.end-main-axis,
.end-sec-axis {
  display: flex;
}

.flx1 {
  flex: 1;
}

.flx2 {
  flex: 2;
}

.flx3 {
  flex: 3;
}

.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.col {
  flex-direction: column;
}

.frow {
  flex-direction: row;
  flex: 1;
}

.fcol {
  flex-direction: column;
  flex: 1;
}

.spc {
  justify-content: space-between;
}

.arnd {
  justify-content: space-around;
}

.even {
  justify-content: space-evenly;
}

.wrap {
  flex-wrap: wrap;
}

.ctr {
  justify-content: center;
  align-items: center;
}

.start-main-axis {
  justify-content: flex-start;
}

.start-sec-axis {
  align-items: flex-start;
}

.end-main-axis {
  justify-content: flex-end;
}

.end-sec-axis {
  align-items: flex-end;
}

.ctr-main-axis {
  justify-content: center;
}

.ctr-cross-axis {
  align-items: center;
}

.self-end {
  align-self: flex-end;
}

.self-ctr {
  align-self: center;
}
