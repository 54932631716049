.gallery-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 2vh 2vw;
    background-color: white;
    border-radius: 15px;
    width: 80px;
    text-align: center;
    text-decoration: none;
    color: black;
    padding: 10px;
}
.menu-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    z-index: 2;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 1.8rem;
    margin: 15px;
    padding: 12px;
}
